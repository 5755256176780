@import url('https://rsms.me/inter/inter.css');



@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Inter';  
}

body {
  margin: 0px 0px 0px 0px;
  background: #303035;
  color: white;
}


#gradient-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  border-radius: 0px 0px 0px 0px;
  --gradient-color-1: #b90e50;
  --gradient-color-2: #032e46;
  --gradient-color-3: #151525;
  --gradient-color-4: #0f3b5e;
}

