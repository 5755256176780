/* 3D tower loader made by: csozi | Website: www.csozi.hu*/

.Boxloader {
    scale: 3;
    height: 50px;
    width: 40px;
    
    
    
  }
  
  .boxload {
    position: relative;
    opacity: 0;
    left: 0px;
  }
  
  .side-left {
    position: absolute;
    background-color: #3d4047;
    width: 19px;
    height: 5px;
    transform: skew(0deg, -25deg);
    top: 14px;
    left: 10px;
  }
  
  .side-right {
    position: absolute;
    background-color: #33363d;
    width: 19px;
    height: 5px;
    transform: skew(0deg, 25deg);
    top: 14px;
    left: -9px;
  }
  
  .side-top {
    position: absolute;
    background-color: #000000;
    width: 20px;
    height: 20px;
    rotate: 45deg;
    transform: skew(-20deg, -20deg);
  }
  
  .box-11 {
    animation: from-left 4s infinite;
  }
  
  .box-12 {
    animation: from-right 4s infinite;
    animation-delay: 1s;
  }
  
  .box-13 {
    animation: from-left 4s infinite;
    animation-delay: 2s;
  }
  
  .box-14 {
    animation: from-right 4s infinite;
    animation-delay: 3s;
  }
  
  @keyframes from-left {
    0% {
      z-index: 20;
      opacity: 0;
      translate: -20px -6px;
    }
  
    20% {
      z-index: 10;
      opacity: 1;
      translate: 0px 0px;
    }
  
    40% {
      z-index: 9;
      translate: 0px 4px;
    }
  
    60% {
      z-index: 8;
      translate: 0px 8px;
    }
  
    80% {
      z-index: 7;
      opacity: 1;
      translate: 0px 12px;
    }
  
    100% {
      z-index: 5;
      translate: 0px 30px;
      opacity: 0;
    }
  }
  
  @keyframes from-right {
    0% {
      z-index: 20;
      opacity: 0;
      translate: 20px -6px;
    }
  
    20% {
      z-index: 10;
      opacity: 1;
      translate: 0px 0px;
    }
  
    40% {
      z-index: 9;
      translate: 0px 4px;
    }
  
    60% {
      z-index: 8;
      translate: 0px 8px;
    }
  
    80% {
      z-index: 7;
      opacity: 1;
      translate: 0px 12px;
    }
  
    100% {
      z-index: 5;
      translate: 0px 30px;
      opacity: 0;
    }
  }