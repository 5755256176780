@tailwind base;
@tailwind components;
@tailwind utilities;

/* C01- 
right menu toggle buttion */

#checkbox {
  display: none;
}

.toggle {
  position: absolute;
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  transition-duration: .3s;
}

.bars {
  width: 100%;
  height: 4px;
  background-color: #f15b04;
  border-radius: 5px;
  transition-duration: .3s;
}

#checkbox:checked + .toggle .bars {
  margin-left: 13px;
}

#checkbox:checked + .toggle #bar2 {
  transform: rotate(135deg);
  margin-left: 0;
  transform-origin: center;
  transition-duration: .3s;
}

#checkbox:checked + .toggle #bar1 {
  transform: rotate(45deg);
  transition-duration: .3s;
  transform-origin: left center;
}

#checkbox:checked + .toggle #bar3 {
  transform: rotate(-45deg);
  transition-duration: .3s;
  transform-origin: left center;
}


/* /* C02- 
//for making fixed-size video player -  */



/* Product Feature cards */

.card {
  z-index: 1;
  width: 290px;
  height: 70px;
  background: #07182E;
  position: relative;
  display: flex;
  place-content: center;
  place-items: center;
  overflow: hidden;
  border-radius: 20px;
}

.card h2 {
  z-index: 1;
  color: white;
  font-size: 2em;
}

.card::before {
  content: '';
  position: absolute;
  width: 100px;
  background-image: linear-gradient(180deg, rgb(0, 183, 255), rgb(255, 48, 255));
  height: 430%;
  animation: rotBGimg 6s linear infinite;
  transition: all 0.9s linear;
}

@keyframes rotBGimg {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.card::after {
  content: '';
  position: absolute;
  background: #07182E;
  ;
  inset: 5px;
  border-radius: 15px;
}  

.navColor{

  background-color: #303035;
}

.box-container0 {
  display: inline-block;
  clip-path: polygon(0 0, 100% 0%, 85% 100%, 0 100%);
  padding-right: 40px;
  padding-top: 4px;
  padding-bottom: 4px;
  background-color: #ffffff;
  border: 5px solid #000;
  border-radius: 0px;
}

.box-container2 {
  display: inline-block;
  clip-path: polygon(35% 0,100% 0, 100% 100%, 5% 100%);
  top: 2px;
  left: -59px;
  padding-left: 30px;
  padding-right: 10px;
  padding-top: 4px;
  padding-bottom: 5px;
  background-color: #f15b04;
  border-radius: 10px;
}
/* 


/* css for alert notification */


.alert {
  position: absolute;
  width: 320px;
  padding-top: 60px;
  border-radius: 10px;
  background: #212121;
  border: 2px solid #ffffff;
  transition: all 0.5s cubic-bezier(0.23, 1, 0.320, 1);
  z-index: 10;
  opacity: 90%;
  box-shadow: 0 0 20px #d7d713;
  backdrop-filter: blur(2px);
  /* animation: blink 4s infinite; */
}

.alert::before {
  content: "\1F446";
  position: absolute;
  top: 29%;
  left: 110px;
  bottom: 0px;
  padding-right: 15px;
  transform: translateY(-50%);
  font-size: 60px;
  color: #d7d713;
  animation: blink-icon 2s infinite;
}

@keyframes blink-icon {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes blink {
  0% {
    border-color: #d7e212;
    box-shadow: 0 0 20px #d7d713;
  }
  50% {
    border-color: transparent;
    box-shadow: none;
  }
  100% {
    border-color: #d7e212;
    box-shadow: 0 0 20px #d7d713;
  }
}




.alert.success {
  background-color: #dff0d8;
  border-color: #d0e9c6;
  color: #3c763d;
}

.alert.info {
  background-color: #d9edf7;
  border-color: #bce8f1;
  color: #31708f;
}

.alert.warning {
  background-color: #000;

  
}

.alert.error {
  background-color: #f2dede;
  border-color: #ebccd1;
  color: #a94442;
}

.alert .close-btn {
  cursor: pointer;
  position: absolute;
  top: 0px;
  right: 8px;
  font-size: 14px;
  font-weight: bold;
}



.alert .close-btn:hover {
  color: #333;
}

 /* to blur the page 1 */


 .blur1 {
  width: 100%;
  height: 100%;
  position: absolute;
  cursor: pointer;
  z-index: 30;
  
}

.blur1-inner {
  width: inherit;
  height: inherit;
  backdrop-filter: blur(0px);
  

}

/* Landing Line - cyclic text*/
.loader {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  height: 100px;
  z-index: 20;
  /* padding-left: 9%; */
  /* position: absolute; */
  /* left: 50%; */
  /* transform: translateX(50%); */
  /* text-align: center; */
  
}

.words {
  overflow: hidden;
}

.word {
  display: block;
  height: 100%;
  padding-left: 10px;
  padding-left: 10px;
  animation: spin_4991 5s infinite;
}



/* Media Queries */
@media screen and (min-width: 1440px) and (max-width: 3500px) {
  .loader {
    font-size: 36px;
    padding-left: 10%;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1440px) {
  .loader {
    font-size: 26px;
    padding-left: 10%;
  }
}

@media screen and (min-width: 631px) and (max-width: 1000px) {
  .loader {
    font-size: 26px;
  }
}


@media screen and (max-width: 631px) {
  .loader {
    font-size: 13px;
  }
}



/* Keyframes */
@keyframes spin_4991 {
  10% {
    transform: translateY(-105%);
  }

  25% {
    transform: translateY(-100%);
  }

  35% {
    transform: translateY(-205%);
  }

  50% {
    transform: translateY(-200%);
  }

  60% {
    transform: translateY(-305%);
  }

  75% {
    transform: translateY(-300%);
  }

  85% {
    transform: translateY(-405%);
  }

  100% {
    transform: translateY(-400%);
  }
}


/* glass effect */

.container {
  position: absolute;
  top: 80px;
  left: 0;
  z-index: 10; /* Set a high z-index value to ensure it appears on top */
  font-family: sans-serif;
}

.container::before,
.container::after {
  content: "";
  background-color: #fab5704c;
  position: absolute;
}

.container::before {
  
  z-index: -1; /* Move the pseudo-element behind the container */
}



.container .box {
  width: 118.675em;
  height: 45.875em;
  padding: 1rem;
  background-color: rgba(255, 255, 255, 0.074);
  border: 1px solid rgba(255, 255, 255, 0.222);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  border-radius: .7rem;
  transition: all ease .3s;
  position: relative;
  z-index: 1; /* Set a higher z-index to keep the box on top */
}

.container .box:hover {
  box-shadow: 0px 0px 20px 1px #ffbb763f;
  border: 1px solid rgba(255, 255, 255, 0.454);
}







/* @media only screen and (max-width: 740px) {
  .player-wrapper {
    right: 700px;
  }
} */

.wave-pos{
  position: relative;
  margin-top: 550px;
  z-index: 0;
}


/* card for Modules  */

.ModuleCard {
  position: relative;
  width: 269px;
  height: 280px;
  border-radius: 30px;
  margin-bottom: 6%;
  background: lightgrey;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 50px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 26px -18px inset;
}

/* css for a vertical line */

.vertical-line {
  width: 5px;
  height: 300px;
  background-color: #fb8c00;
 
margin-left:  125px;
margin-right: 50px;
}




/* css for a horizontal line */

.horizontal-line {
  position: relative;
  width: 15%;
  height: 0;
  border-top: 3px solid black;
}

.horizontal-line::before,
.horizontal-line::after {
  content: "";
  position: absolute;
  top: -5px;
  width: 6px;
  height: 6px;
  background-color: black;
  border-radius: 50%;
}

.horizontal-line::before {
  left: -3px;
}

.horizontal-line::after {
  right: -3px;
}


@media screen and (min-width: 1440px) and (max-width: 3500px) {
 .horizontal-line {
  margin: 3% auto;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1440px) {
 .horizontal-line {
  margin: 3% auto;
  }
}

@media screen and (min-width: 631px) and (max-width: 1000px) {
 .horizontal-line {
  margin: 10% auto;
  }
}






/* Scroll to top */

.top-to-btm{
  position: relative;
}
.icon-position{
  position: fixed;
  bottom: 40px;
  right: 25px;
  z-index: 20;
}
.icon-style{
  background-color: #551B54;
  border: 2px solid #fff;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  color: #fff;
  cursor: pointer;
  animation: movebtn 3s ease-in-out infinite;
  transition: all .5s ease-in-out;
}
.icon-style:hover{
  animation: none;
  background: #fff;
  color: #551B54;
  border: 2px solid #551B54;
}
@keyframes movebtn {
  0%{
    transform: translateY(0px);
  }
  25%{
    transform: translateY(20px);
  }
  50%{
    transform: translateY(0px);
  }
  75%{
    transform: translateY(-20px);
  }
  100%{
    transform: translateY(0px);
  }
}


@media screen and (max-width: 631px) {
 .horizontal-line {
  margin: 10% auto;
  }
}


.App {
  font-family: sans-serif;
  text-align: center;
}


@media screen and (min-width: 1440px) and (max-width: 3500px) {
  .health_bar{
    transform: scale(1,1);
  }
}

@media screen and (min-width: 1000px) and (max-width: 1440px) {
  .health_bar{
    transform: scale(1,1);
  }
}

@media screen and (min-width: 631px) and (max-width: 1000px) {
  .health_bar{
    transform: scale(1,1);
  }
}

@media screen and (min-width: 376px) and (max-width: 631px) {
  .health_bar{
    font-size: 8px;
  }
}

@media screen and (max-width: 376px) {
  .health_bar{
    font-size: 8px;
  }
}


@media screen and (min-width: 1440px) and (max-width: 3500px) {
  .anomaly_bar{
    font-size: 14px;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1440px) {
  .anomaly_bar{
    font-size: 14px;
  }
}

@media screen and (min-width: 631px) and (max-width: 1000px) {
  .anomaly_bar{
    font-size: 14px;
  }
}

@media screen and (min-width: 376px) and (max-width: 631px) {
  .anomaly_bar{
    font-size: 8px;
  }
}

@media screen and (max-width: 376px) {
  .anomaly_bar{
    font-size: 8px;
  }
}


@media screen and (min-width: 1440px) and (max-width: 3500px) {
  .Part_bar{
    font-size: 12px;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1240px) {
  .Part_bar{
    font-size: 12px;
  }
}

@media screen and (min-width: 631px) and (max-width: 1000px) {
  .Part_bar{
    font-size: 12px;
  }
}

@media screen and (min-width: 376px) and (max-width: 631px) {
  .Part_bar{
    font-size: 7px;
  }
}

@media screen and (max-width: 376px) {
  .Part_bar{
    font-size: 7px;
  }
}


.circlei {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #000000;
  color: #ffffff;
  text-align: center;
  line-height: 20px;
  font-size: 14px;
}

.circlei:before {
  content: "i";
  font: bold;
}
