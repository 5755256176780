

.container_As {
  position: relative;
}

.overlay_As{
  background-color: rgba(48, 48, 53, 0.9);
  z-index: 1;
}


.content_As {
  position:absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  font-size: 24px;
  z-index: 3;
}



@media screen and (min-width: 1440px) and (max-width: 3500px) {
.content_As{
 top:45%
  }
}

@media screen and (min-width: 1000px) and (max-width: 1440px) {
.content_As{
 top: 45%
  }
}

@media screen and (min-width: 631px) and (max-width: 1000px) {
.content_As{
 top: 45%
  }
}

@media screen and (min-width: 376px) and (max-width: 631px) {
.content_As{
 top: 45%
  }
}

@media screen and (max-width: 376px) {
.content_As{
 top: 45%
  }
}
