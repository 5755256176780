.slider {
    position: relative;
    width: 100vw;
  
    
  }
  


  .slider-item {
    position: absolute;
    left: 0;
    right: 0;
    height: 100vh;
    bottom: 0;
    opacity: 0;
    transition: opacity 1s ease-in-out;
  }
  
  .slider-item.active {
    opacity: 1;
  }
  
    

  .slider-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0%;
    left: 0;
    right: 0;
    height: 39%;
    background-color: rgba(48, 48, 53, 1);
  }


  
  .slider-indicator {
    display: flex;
    align-items: center;
  }
  
  .slider-indicator-item {
    display: flex;
    font-family: Kanit;
    flex-direction: column;
    margin-top: 5%;
    align-items: center;
    cursor: pointer;
     /* Add margin for spacing */
    color: #808080;
  }

  .slider-indicator-item:hover {
    animation: bounce 0.5s;
  }
  
  @keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
  }
  
  
  .slider-indicator-item.active {
    color: #f15b04;
  }
  
  .slider-indicator-item .indicator-name {
    margin-left: 65px;
    margin-right: 65px;
    margin-bottom: 25px;
  }
  
 


    /* * Media Queries */ 
    @media screen and (min-width: 1440px) and (max-width: 3500px) {
      .slider-indicator-item{
        font-size: 46px;
        margin-right: 10px;
      }
    }
    
    @media screen and (min-width: 1000px) and (max-width: 1440px) {
      .slider-indicator-item{
        font-size: 26px;
        margin-right: 10px;
      }
    }
    
    @media screen and (min-width: 631px) and (max-width: 1000px) {
      .slider-indicator-item{
        font-size: 26px;
        margin-right: 10px;
      }
    }
    
    @media screen and (min-width: 376px) and (max-width: 631px) {
      .slider-indicator-item{
        font-size: 13px;
        margin-right: 6px;
      }
    }
    
    @media screen and (max-width: 376px) {
      .slider-indicator-item{
        font-size: 10px;
        margin-right: 0px;
      }
    }














    






     /* * Media Queries */ 
 @media screen and (min-width: 1440px) and (max-width: 3500px) {
  .slider{
    height: 140%;
  }
  .slider-item{
    top:35%
  }
  .slider-controls{
    height: 30%;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1440px) {
  .slider{
    height: 140%;
  }
  .slider-item{
    top:20%
  }
  .slider-controls{
    height: 28%;
  }
}

@media screen and (min-width: 631px) and (max-width: 1000px) {
  .slider{
    height: 180%;
  }
  .slider-item{
    top:20%
  }
  .slider-controls{
    height: 24%;
  }
}

@media screen and (min-width: 376px) and (max-width: 631px) {
  .slider{
    height: 230%;
  }
  .slider-item{
    top:15%
  }
  .slider-controls{
    height: 18%;
  }
}

@media screen and (max-width: 376px) {
  .slider{
    height: 230%;
  }
  .slider-item{
    top:15%
  }
  .slider-controls{
    height: 18%;
  }
}

























/* scale of the Content */


/* * Media Queries */ 
@media screen and (min-width: 1440px) and (max-width: 3500px) {
  .slider-item.active{
    transform: scale(0.9, 0.9);
  }
}

@media screen and (min-width: 1000px) and (max-width: 1440px) {
  .slider-item.active{
    transform: scale(0.7, 0.7);
  }
}

@media screen and (min-width: 631px) and (max-width: 1000px) {
  .slider-item.active{
    transform: scale(1,1);
  }
}

@media screen and (min-width: 376px) and (max-width: 631px) {
  .slider-item.active{
    transform: scale(1,1);
  }
}

@media screen and (max-width: 376px) {
  .slider-item.active{
    transform: scale(1,1);
  }
}
